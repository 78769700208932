<template>
  <div>
    <FamilyImageBanner />
    <section>
      <b-container>
        <b-row>
          <b-col>
            <h1>Jaaropgave</h1>
            <b-card>
              <div class="d-flex align-self-center">
                <div v-if="$auth.user && $auth.hasRole(['sender', 'superadmin', 'admin'])">
                  <b-button variant="primary" class="mr-3" v-b-modal.newAnnualStatement @click="getUsers">
                    Nieuwe jaaropgave
                  </b-button>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <b-table class="mt-2"
                  responsive
                  borderless
                  :items="statements"
                  :fields="fields"
                >
                  <template #cell(id)="row">
                    {{row.index + 1}}
                  </template>

                  <template #cell(stakeholder)="row">
                    <b-avatar-group>
                      <b-avatar v-b-tooltip.hover :title="row.item.receiver.fullname" class="pull-up" :src="row.item.receiver.image" :text="row.item.receiver.firstname[0] + row.item.receiver.lastname[0]"></b-avatar>
                    </b-avatar-group>
                  </template>

                  <template #cell(download)="row">
                    <div class="btn-group">
                      <b-button class="btn" v-if="!row.item.approve" variant="primary" @click="approve(row.item)" >Akkoordeer</b-button>
                      <b-button class="btn" variant="primary" @click="download(row.item)">Download</b-button>
                    </div>
                  </template>
                </b-table>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <b-modal id="newAnnualStatement" no-enforce-focus no-close-on-backdrop no-footer>
      <template #modal-title>
        <h3>Jaaropgave</h3>
      </template>

      <template #modal-footer>
        <b-button variant="primary" :disabled="!user_selected || !fiscalYear" @click="saveAnnualStatement()">Opslaan</b-button>
      </template>

      <b-container>
        <b-row class="d-flex flex-column">
          <h4>Voor wie is deze jaaropgave?</h4>
          <b-form-select v-model="user_selected" @change="getFiscalYears($event)">
            <template #first>
              <b-form-select-option :value="null" disabled>-- Selecteer een familielid --</b-form-select-option>
            </template>
            <b-form-select-option v-for="(user, index) in users" :key="index" :value="user.hashid">{{user.fullname}}</b-form-select-option>
          </b-form-select>
        </b-row>
        <b-row class="mt-1">
          <div class="d-flex justify-content-center">
            <h4>Fiscaal jaar</h4>
            <InfoButton id="fiscal-year-info" :bgBlue="true" text="Je kunt maximaal 7 jaar in het verleden jaaropgaves opvragen."  />
          </div>
          <b-form-select v-model="fiscalYear" :options="fiscalYears" :disabled="fiscalYears.length < 1 ? true : false"></b-form-select>
        </b-row>
        <b-row class="mt-2">
          <b-alert
            variant="danger"
            show
          >
            <div class="alert-body">
              <span><strong>Let op!</strong> Als je de jaaropgave accoordeert, worden alle voorgaande mutaties permanent. Deze kunnen niet meer worden aangepast.</span>
            </div>
          </b-alert>
        </b-row>
      </b-container>
      
    </b-modal>
  </div>
</template>

<script>
// import store from '@/store'
import FamilyImageBanner from '@/components/family-image-banner.vue'


export default {
  components: {
    FamilyImageBanner,
  },
  data() {
    return {
      user_selected: null,
      users: [],
      fiscalYears: [],
      fiscalYear: null,
      fields: [
        { key: 'year', label: 'Fiscaal Jaar'},
        { key: 'approve', label: 'Geaccordeerd op', formatter: function(value) { return value == null ? "Niet geaccordeerd." : (new Date(value.replace(/-/g, '/').replace('T', ' '))).toLocaleDateString("nl-NL"); }},
        { key: 'stakeholder', label: 'Voor relatie?'},
        { key: 'download', label: '', tdClass: 'text-right',},
      ],
      statements: [],
    }
  },
  methods: {
    getUsers() {
      // TO DO - change this to getting only children with whom a contract has been signed (not all children) + accountholder him/herself
      this.users = [];
      this.users = [...this.$auth.db.children.map(e => e.child)];
      this.users.push(this.$auth.db);
      console.log(this.users);
    },
    getFiscalYears(user) {
      console.log(user);
      this.$http.get(`/annualstatement/years/`,{params: {hashid: user}}).then((response)=> {
          this.fiscalYears = response.data.data;
          console.log(response.data.data);
      });
    },
    saveAnnualStatement() {
      let _this = this;
      this.$http.post("/annualstatement", {
        "user_id": this.user_selected,
        "year": this.fiscalYear,
      }).then((response)=> {
        console.log(response);
        _this.$bvModal.hide("newAnnualStatement");
        _this.getStatements();
      });
    },
    approve(item) {
      let _this = this;
      this.$http.get("/annualstatement/approve", {
        params: {
          hashid: item.hashid,
        },
        responseType: 'arraybuffer'
      }).then(response => {
        _this.getStatements();
        let blob = new Blob([response.data], { type: 'application/pdf' } ),
        url = window.URL.createObjectURL(blob)

        window.open(url);
      });
    },
    download(item) {
      console.log(item);
      this.$http.get("/annualstatement/download", {
        params: {
          hashid: item.hashid
        },
        responseType: 'arraybuffer'
      }).then(response => {
        let blob = new Blob([response.data], { type: 'application/pdf' } ),
        url = window.URL.createObjectURL(blob)

        window.open(url);
      });
    },
    getStatements() {
      let _this = this;
      this.$http.get("/annualstatements").then(response => {
        _this.statements = response.data.statements;
      });
    }
  },

  mounted() {
    this.getStatements();
  }
}
</script>

<style lang="scss">

</style>
